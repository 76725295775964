import { FC, forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatDistanceStrict } from 'date-fns';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import RefreshCcw01Icon from '@untitled-ui/icons-react/build/esm/RefreshCcw01';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  createTheme,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useApi } from 'src/hooks/use-api';
import { Email, EmailSearchRequest, SortDir } from 'src/lib-api';
import { paths } from 'src/paths';
import NextLink from 'next/link';
import { neutral } from 'src/theme/colors';
import { useRouter } from 'next/router';
import { stringAvatar } from 'src/utils/string-avatar';
import { OverviewFilters } from 'src/pages/dashboard/overview';
import { OverviewLoadingChart } from './overview-loading-chart';

interface OverviewInboxProps extends OverviewFilters {}

export const OverviewInbox: FC<OverviewInboxProps> = (props) => {
  const { emailApi } = useApi();
  const { dealers } = props;
  const [emailLeads, setEmails] = useState<Email[] | undefined | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const router = useRouter();

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const emailSearchRequest: EmailSearchRequest = {
        rowPerPage: 5,
        page: 0,
        filters: { query: '' },
        sortBy: 'createdAt',
        sortDir: SortDir.Desc,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await emailApi
        .emailSearch({ emailSearchRequest })
        .then((res) => {
          setEmails(res?.data);
        })
        .catch((e) => {})
        .finally(() => setIsLoading(false));
    };

    load();
  }, [emailApi, dealers]);

  return (
    <Card>
      <CardHeader title="Henvendelser" />

      {!emailLeads && (
        <Box width={1} justifyContent={'center'} display={'flex'} padding={2}>
          <CircularProgress />
        </Box>
      )}

      {emailLeads?.length === 0 && (
        <Typography align="center" padding={2}>
          Ingen henvendelser
        </Typography>
      )}

      <List disablePadding>
        {emailLeads?.map((lead) => {
          const ago = formatDistanceStrict(lead.createdAt!, new Date(), {
            addSuffix: true,
          });

          return (
            <ListItem
              onClick={() => router.push(paths.dashboard.leads.emails)}
              key={lead.id!}
              sx={{
                '&:hover': {
                  backgroundColor: 'action.hover',
                  cursor: 'pointer',
                },
              }}
            >
              <ListItemAvatar>
                <Avatar {...stringAvatar(lead.name!.toUpperCase())} />
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    variant="subtitle2"
                  >
                    {lead.name}
                  </Typography>
                }
                secondary={
                  <Typography
                    color="text.secondary"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    variant="body2"
                  >
                    {lead.message}
                  </Typography>
                }
                sx={{ pr: 2 }}
              />
              <Typography
                color="text.secondary"
                sx={{ whiteSpace: 'nowrap' }}
                variant="caption"
              >
                {ago}
              </Typography>
            </ListItem>
          );
        })}
      </List>
      <Box padding={2}>
        <OverviewLoadingChart isLoading={isLoading} />
      </Box>
      <Divider />
      <CardActions>
        <NextLink
          href={paths.dashboard.leads.emails}
          passHref
          color="inherit"
          style={{ textDecoration: 'none', color: neutral[900] }}
        >
          <Button
            color="inherit"
            endIcon={
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            }
            size="small"
          >
            Vis alle
          </Button>
        </NextLink>
      </CardActions>
    </Card>
  );
};

OverviewInbox.propTypes = {};
