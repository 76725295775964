import { FC, use, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import { neutral } from 'src/theme/colors';
import NextLink from 'next/link';
import CountUp from 'react-countup';
import InfoCircleIcon from '@untitled-ui/icons-react/build/esm/InfoCircle';
import { AuctionKeyDataSearchResponse } from 'src/lib-api';
import { AuctionListCard } from '../../auctions/auction-list-card';
import { AuctionThumbnailCard } from '../../auctions/auction-thumbnail-card';

interface AuctionsDefaultCardProps {
  label: string;
  auctionResult: AuctionKeyDataSearchResponse | undefined;
  decimals?: number;
  caption?: string;
  zeroText?: string;
  actionText?: string;
  infoText?: string;
  icon?: any;
  href?: string;
}

export const AuctionsDefaultCard: FC<AuctionsDefaultCardProps> = (props) => {
  const {
    auctionResult,
    label,
    href,
    decimals,
    caption,
    zeroText,
    infoText,
    actionText,
  } = props;
  const stackRef = useRef<HTMLDivElement>(null);
  const overflowRef = useRef<HTMLDivElement>(null);
  const [displayRightArrow, setDisplayRightArrow] = useState(false);
  const [displayLeftArrow, setDisplayLeftArrow] = useState(false);

  useEffect(() => {
    var id = setInterval(() => {
      if (overflowRef.current && stackRef.current) {
        setDisplayRightArrow(
          overflowRef.current?.clientWidth > stackRef.current?.clientWidth &&
            stackRef.current?.scrollLeft! <
              stackRef.current?.scrollWidth! - stackRef.current?.clientWidth!
        );
        setDisplayLeftArrow(
          overflowRef.current?.clientWidth > stackRef.current?.clientWidth &&
            stackRef.current?.scrollLeft! > 0
        );
      }
    }, 1000);
    return () => clearInterval(id);
  }, []);

  const onScrollRight = () => {
    const bonus = 0 == stackRef.current?.scrollLeft!;
    stackRef.current?.scrollBy({
      left: 269 + (bonus ? 12 : 0),
      behavior: 'smooth',
    });
  };

  const onScrollLeft = () => {
    const bonus =
      stackRef.current?.clientWidth! >= stackRef.current?.scrollLeft!;
    stackRef.current?.scrollBy({
      left: -269 + (bonus ? -24 : 0),
      behavior: 'smooth',
    });
  };

  return (
    <Card style={{ position: 'relative' }}>
      <Stack
        ref={stackRef}
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={3}
        sx={{
          overflowX: 'hidden',
          px: 4,
          py: 3,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems={'center'}>
            <div>{props.icon ? props.icon : <></>}</div>
            <Typography marginLeft={2} color="text.secondary" variant="h6">
              {label}
            </Typography>
          </Box>
          <Typography
            color="text.primary"
            variant="h4"
            sx={{ textAlign: { xs: 'center', sm: 'unset' } }}
          >
            {auctionResult === undefined && (
              <Box
                alignItems={'center'}
                display="flex"
                justifyContent={'center'}
                sx={{ height: { md: 328 } }}
              >
                <CircularProgress size="1.5rem" />
              </Box>
            )}
            {auctionResult !== undefined && (
              <>
                <Box
                  ref={overflowRef}
                  display="flex"
                  alignItems={'center'}
                  width={'fit-content'}
                >
                  {auctionResult.data?.map((auctionKeyData) => {
                    return (
                      <Box display="flex" key={auctionKeyData.auctionId}>
                        <AuctionThumbnailCard auctionKeyData={auctionKeyData} />
                      </Box>
                    );
                  })}

                  {displayLeftArrow && (
                    <Box
                      position={'absolute'}
                      bgcolor={'#f3f4f6'}
                      left={20}
                      borderRadius={'50%'}
                      width={40}
                      height={40}
                      display={'flex'}
                      boxShadow={'0px 5px 22px rgba(0, 0, 0, 0.4)'}
                    >
                      <IconButton onClick={onScrollLeft} size="medium">
                        <img src="/assets/icons/left-arrow.svg" width={24} />
                      </IconButton>
                    </Box>
                  )}
                  {displayRightArrow && (
                    <Box
                      position={'absolute'}
                      bgcolor={'#f3f4f6'}
                      right={20}
                      width={40}
                      height={40}
                      borderRadius={'50%'}
                      display={'flex'}
                      boxShadow={'0px 5px 22px rgba(0, 0, 0, 0.4)'}
                    >
                      <IconButton onClick={onScrollRight} size="medium">
                        <img src="/assets/icons/right-arrow.svg" width={24} />
                      </IconButton>
                    </Box>
                  )}
                </Box>

                {auctionResult.data?.length === 0 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Box
                        alignItems={'center'}
                        display="flex"
                        justifyContent={'center'}
                        sx={{ height: { md: 328 } }}
                      >
                        <Typography color="text.secondary" variant="body2">
                          {zeroText ?? 'Ingen biler'}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <CardActions>
        <Box width={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <NextLink
              href={href ?? '#'}
              passHref
              color="inherit"
              style={{
                textDecoration: 'none',
                color: neutral[900],
                visibility: href ? 'visible' : 'hidden',
              }}
            >
              <Button
                color="inherit"
                endIcon={
                  <SvgIcon>
                    <ArrowRightIcon />
                  </SvgIcon>
                }
                size="small"
              >
                {actionText ?? 'Vis alle'}{' '}
                {(actionText === 'Vis alle' || actionText === undefined) &&
                auctionResult?.count
                  ? auctionResult.count + ' biler'
                  : undefined}
              </Button>
            </NextLink>
            {infoText && (
              <Box paddingRight={1.5}>
                <Tooltip title={infoText}>
                  <SvgIcon>
                    <InfoCircleIcon />
                  </SvgIcon>
                </Tooltip>
              </Box>
            )}
          </Stack>
        </Box>
      </CardActions>
    </Card>
  );
};

AuctionsDefaultCard.propTypes = {
  label: PropTypes.string.isRequired,
  auctionResult: PropTypes.any,
  icon: PropTypes.any,
  href: PropTypes.string,
};
