import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverviewDefaultCard } from './overview-default-card';
import { useApi } from 'src/hooks/use-api';
import {
  Dealer,
  RatingAverageRequest,
  RatingAverageResponse,
  VipViewCountRequest,
} from 'src/lib-api';
import { OverviewFilters } from 'src/pages/dashboard/overview';

interface OverviewRatingAvarageProps extends OverviewFilters {}

export const OverviewRatingAvarage: FC<OverviewRatingAvarageProps> = (
  props
) => {
  const { classifiedsStatsApi } = useApi();
  const { startDate, endDate, dealers } = props;
  const [ratingAverage, setRatingAverage] = useState<
    RatingAverageResponse | undefined
  >(undefined);

  useEffect(() => {
    const load = async () => {
      setRatingAverage(undefined);

      const request: RatingAverageRequest = {
        startDate,
        endDate,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await classifiedsStatsApi
        .ratingAverage(request)
        .then((res) => {
          setRatingAverage(res);
        })
        .catch((e) => {});
    };

    load();
  }, [classifiedsStatsApi, startDate, endDate, dealers]);

  return (
    <OverviewDefaultCard
      label="Brugernes rating"
      amount={ratingAverage?.average!}
      maxAmount={ratingAverage?.maxRating}
      decimals={1}
      caption={`${ratingAverage?.count} besvarelser`}
      zeroText="Ingen besvarelser"
      infoText="Bilhandel sender et spørgeskema til brugerne 14 dage efter de har kontaktet en forhandler, for at høre mere om deres oplevelse. Vi ringer også en del af dem op. Vi spørger bl.a. “Bedøm din oplevelse med forhandler X” den samlede brugerrating kan du ser her."
      icon={<img src="/assets/iconly/star.svg" width={48} />}
      href={'/dashboard/leads?onlySurvey=true'}
    />
  );
};

OverviewRatingAvarage.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
