import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverviewDefaultCard } from './overview-default-card';
import { useApi } from 'src/hooks/use-api';
import { OverviewFilters } from 'src/pages/dashboard/overview';
import { IwFacebookImpressionCountRequest } from 'src/lib-api';

interface OverviewFacebookImpressionCountProps extends OverviewFilters {}

export const OverviewFacebookImpressionCount: FC<
  OverviewFacebookImpressionCountProps
> = (props) => {
  const { classifiedsStatsApi } = useApi();
  const { startDate, endDate, dealers } = props;
  const [amount, setAmount] = useState<number | undefined>(undefined);

  useEffect(() => {
    const load = async () => {
      setAmount(undefined);

      const request: IwFacebookImpressionCountRequest = {
        startDate,
        endDate,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await classifiedsStatsApi
        .iwFacebookImpressionCount(request)
        .then((res) => {
          setAmount(+res);
        })
        .catch((e) => {});
    };

    load();
  }, [classifiedsStatsApi, startDate, endDate, dealers]);

  return (
    <OverviewDefaultCard
      label="Facebook visninger"
      amount={amount}
      icon={<img src="/assets/iconly/facebook-views.png" width={48} />}
      href={'#overview-iw-facebook-chart'}
    />
  );
};

OverviewFacebookImpressionCount.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
