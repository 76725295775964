import { FC, useEffect, useState } from 'react';
import { useApi } from 'src/hooks/use-api';
import {
  AuctionKeyDataSearchResponse,
  AuctionMessagesRequest,
  SearchAuctionsRequest,
  SearchThreadsRequest,
  SearchThreadsResponse,
  SortDir,
} from 'src/lib-api';
import { AuctionsDefaultTextCard } from './auctions-default-text-card';

interface AuctionsMessagesProps {}

export const AuctionsMessages: FC<AuctionsMessagesProps> = (props) => {
  const { auctionApi } = useApi();
  const [result, setResult] = useState<SearchThreadsResponse | undefined>(
    undefined
  );

  useEffect(() => {
    const load = async () => {
      setResult(undefined);

      const request: SearchThreadsRequest = {
        page: 0,
        rowPerPage: 10,
      };

      await auctionApi
        .auctionSearchthreads({ searchThreadsRequest: request })
        .then((res) => {
          setResult(res);
        })
        .catch((e) => {});
    };

    load();
  }, [auctionApi]);

  return (
    <AuctionsDefaultTextCard
      label="Beskeder"
      zeroText="Ingen åbne bud"
      actionText={result?.count === 0 ? 'Find den næste bil' : 'Vis alle'}
      texts={result?.data
        ?.filter((p) => !p.lastMessage?.isSenderSelf)
        .slice(0, 5)
        .map((auction) => {
          return {
            title: auction.auctionHeadline ?? '',
            link: `/dashboard/auctions/auction?auctionid=${auction.auctionId}&tab=messages`,
            text: auction.lastMessage?.message
              ? auction.lastMessage.message.substring(0, 20) +
                (auction.lastMessage.message.length > 20 ? '...' : '') +
                (!auction.lastMessage.hasRead ? ' (ulæst)' : '')
              : '',
            createdAt: auction.lastMessage?.createdAt,
          };
        })}
      icon={<img src="/assets/iconly/lead.png" width={48} />}
      href={
        result?.count === 0
          ? '/dashboard/auctions/active'
          : '/dashboard/auctions-messages'
      }
    />
  );
};
