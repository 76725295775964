import { FC, useEffect, useState } from 'react';
import { useApi } from 'src/hooks/use-api';
import {
  AuctionKeyDataSearchResponse,
  SearchAuctionsRequest,
  SortDir,
} from 'src/lib-api';
import { AuctionsDefaultCard } from './auctions-default-card';

interface LatestWonProps {}

export const LatestWon: FC<LatestWonProps> = (props) => {
  const { auctionApi } = useApi();
  const [result, setResult] = useState<
    AuctionKeyDataSearchResponse | undefined
  >(undefined);

  useEffect(() => {
    const load = async () => {
      setResult(undefined);

      const request: SearchAuctionsRequest = {
        rowPerPage: 1,
        page: 0,
        sortBy: 'createdAt',
        sortDir: SortDir.Desc,
        filters: {
          status: 'won',
        },
      };

      await auctionApi
        .auctionSearch({ searchAuctionsRequest: request })
        .then((res) => {
          setResult(res);
        })
        .catch((e) => {});
    };

    load();
  }, [auctionApi]);

  return (
    <AuctionsDefaultCard
      label="Senest vundet"
      auctionResult={result}
      zeroText="Ingen vundne"
      actionText={result?.count === 0 ? 'Begynd at byde' : 'Vis alle'}
      icon={<img src="/assets/iconly/views.png" width={48} />}
      href={
        result?.count === 0
          ? '/dashboard/auctions/active'
          : '/dashboard/auctions/won'
      }
    />
  );
};
