import { FC, useEffect, useState } from 'react';
import type { ApexOptions } from 'apexcharts';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from 'src/components/chart';
import { useApi } from 'src/hooks/use-api';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import { OverviewFilters } from 'src/pages/dashboard/overview';
import { CallTrackingAnsweringChartRequest } from 'src/lib-api';

interface AnsweringStatsProps extends OverviewFilters {}

const labels: string[] = ['Besvaret'];

const useChartOptions = (): ApexOptions => {
  const theme = useTheme();

  return {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: ['#1067B6', '#2ED381'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: 'solid',
    },
    labels,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    stroke: {
      show: false,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      fillSeriesColor: false,
    },
  };
};

export const AnsweringStats: FC<AnsweringStatsProps> = (props) => {
  const { classifiedsStatsApi } = useApi();
  const { startDate, endDate, dealers } = props;

  const [total, setTotal] = useState<number | undefined>(undefined);
  const [percentage, setPercentage] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const chartOptions = useChartOptions();

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const request: CallTrackingAnsweringChartRequest = {
        startDate,
        endDate,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await classifiedsStatsApi
        .callTrackingAnsweringChart(request)
        .then((res) => {
          const percentage =
            res.total! > 0 && res.amount! > 0
              ? Math.round((res.amount! / res.total!) * 1000) / 10
              : 0;

          setPercentage(percentage);
          setTotal(res.total!);
        })
        .catch((e) => {})
        .finally(() => setIsLoading(false));
    };

    load();
  }, [classifiedsStatsApi, startDate, endDate, dealers]);

  return (
    <Card>
      <CardHeader title="Besvarelse af opkald" />

      {total === undefined && (
        <Box display={'flex'} justifyContent={'center'} padding={2}>
          <CircularProgress />
        </Box>
      )}

      {total !== undefined && percentage !== undefined && (
        <Box sx={{ p: 2 }}>
          <Chart
            height={300}
            options={chartOptions}
            series={[percentage!]}
            type="radialBar"
          />
        </Box>
      )}

      <Divider />
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            px: 2,
            py: 3,
            textAlign: 'center',
            '&:not(:last-of-type)': {
              borderRight: 1,
              borderColor: 'divider',
            },
          }}
        >
          <Typography variant="h4">
            <CountUp end={total ?? 0} separator="." />
          </Typography>
          <Typography color="text.secondary" variant="overline">
            Opkald i alt
          </Typography>
          <Box
            sx={{
              width: '100%',
              visibility: isLoading ? 'visible' : 'hidden',
            }}
          >
            <LinearProgress />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

AnsweringStats.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
