import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import InfoCircleIcon from '@untitled-ui/icons-react/build/esm/InfoCircle';
import { formatDistanceStrict } from 'date-fns';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { FC } from 'react';
import { neutral } from 'src/theme/colors';

interface Text {
  title: string;
  link: string;
  text: string;
  createdAt?: Date;
}

interface AuctionsDefaultTextCardProps {
  label: string;
  texts: Text[] | undefined;
  decimals?: number;
  caption?: string;
  zeroText?: string;
  actionText?: string;
  infoText?: string;
  icon?: any;
  href?: string;
}

export const AuctionsDefaultTextCard: FC<AuctionsDefaultTextCardProps> = (
  props
) => {
  const {
    texts,
    label,
    href,
    decimals,
    caption,
    zeroText,
    infoText,
    actionText,
  } = props;
  const router = useRouter();

  return (
    <Card>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={3}
        sx={{
          px: 4,
          py: 3,
          minHeight: 465.3,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems={'center'}>
            <div>
              {props.icon ? (
                props.icon
              ) : (
                <img src="/assets/iconly/iconly-glass-paper.svg" width={48} />
              )}
            </div>
            <Typography marginLeft={2} color="text.secondary" variant="h6">
              {label}
            </Typography>
          </Box>
          <Typography
            color="text.primary"
            variant="h4"
            sx={{ textAlign: { xs: 'center', sm: 'unset' }, minHeight: 359.69 }}
          >
            {texts === undefined && (
              <Box
                alignItems={'center'}
                display="flex"
                justifyContent={'center'}
              >
                <CircularProgress size="1.5rem" />
              </Box>
            )}
            {texts !== undefined && (
              <Box>
                <List disablePadding>
                  {texts?.map((lead) => {
                    const ago = formatDistanceStrict(
                      lead.createdAt!,
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    );

                    return (
                      <ListItem
                        onClick={() => router.push(lead.link)}
                        key={lead.link}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'action.hover',
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              variant="subtitle2"
                            >
                              {lead.title}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              color="text.secondary"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              variant="body2"
                            >
                              {lead.text}
                            </Typography>
                          }
                          sx={{ pr: 2 }}
                        />
                        <Typography
                          color="text.secondary"
                          sx={{ whiteSpace: 'nowrap' }}
                          variant="caption"
                        >
                          {ago}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
                {texts?.length === 0 && (
                  <Box
                    alignItems={'center'}
                    display="flex"
                    justifyContent={'center'}
                  >
                    <Typography color="text.secondary" variant="body2">
                      {zeroText ?? 'Ingen biler'}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <CardActions>
        <Box width={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <NextLink
              href={href ?? '#'}
              passHref
              color="inherit"
              style={{
                textDecoration: 'none',
                color: neutral[900],
                visibility: href ? 'visible' : 'hidden',
              }}
            >
              <Button
                color="inherit"
                endIcon={
                  <SvgIcon>
                    <ArrowRightIcon />
                  </SvgIcon>
                }
                size="small"
              >
                {actionText ?? 'Vis alle'}{' '}
              </Button>
            </NextLink>
            {infoText && (
              <Box paddingRight={1.5}>
                <Tooltip title={infoText}>
                  <SvgIcon>
                    <InfoCircleIcon />
                  </SvgIcon>
                </Tooltip>
              </Box>
            )}
          </Stack>
        </Box>
      </CardActions>
    </Card>
  );
};

AuctionsDefaultTextCard.propTypes = {
  label: PropTypes.string.isRequired,
  texts: PropTypes.any,
  icon: PropTypes.any,
  href: PropTypes.string,
};
