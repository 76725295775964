import { FC, useEffect, useState } from 'react';
import { OverviewDefaultCard } from './overview-default-card';
import { useApi } from 'src/hooks/use-api';
import { paths } from 'src/paths';
import { ActiveFavoritesCountRequest } from 'src/lib-api';
import { OverviewFilters } from 'src/pages/dashboard/overview';

interface OverviewActiveFavoriteCountProps extends OverviewFilters {}

export const OverviewActiveFavoriteCount: FC<OverviewActiveFavoriteCountProps> = (props) => {
  const { classifiedsStatsApi } = useApi();
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const { dealers } = props;

  useEffect(() => {
    const load = async () => {
      setAmount(undefined);

      const request: ActiveFavoritesCountRequest = {};
      request.dealerIds =
        dealers && dealers?.length > 0 ? dealers.map((x) => x.id!) : undefined;

      await classifiedsStatsApi
        .activeFavoritesCount(request)
        .then((res) => {
          setAmount(+res);
        })
        .catch(() => {});
    };

    load();
  }, [classifiedsStatsApi, dealers]);

  return (
    <>
      <OverviewDefaultCard
        label="Dine biler gemt som favorit"
        amount={amount}
        href={`${paths.dashboard.classifieds.index}?only-favorites=true`}
        icon={<img src="/assets/iconly/favoritter2.svg" width={48} />}
      />
    </>
  );
};
