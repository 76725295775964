import { FC, useEffect, useState } from 'react';
import { useApi } from 'src/hooks/use-api';
import {
  AuctionKeyDataSearchResponse,
  SearchAuctionsRequest,
  SortDir,
} from 'src/lib-api';
import { AuctionsDefaultTextCard } from './auctions-default-text-card';
import { formattedPriceExpectation } from 'src/utils/auction';

interface AuctionsClosedBidsProps {}

export const AuctionsClosedBids: FC<AuctionsClosedBidsProps> = (props) => {
  const { auctionApi } = useApi();
  const [result, setResult] = useState<
    AuctionKeyDataSearchResponse | undefined
  >(undefined);

  useEffect(() => {
    const load = async () => {
      setResult(undefined);

      const request: SearchAuctionsRequest = {
        rowPerPage: 5,
        page: 0,
        filters: {
          status: 'closedbid',
        },
        sortBy: 'createdAt',
        sortDir: SortDir.Desc,
      };

      await auctionApi
        .auctionSearch({ searchAuctionsRequest: request })
        .then((res) => {
          setResult(res);
        })
        .catch((e) => {});
    };

    load();
  }, [auctionApi]);

  return (
    <AuctionsDefaultTextCard
      label="Afviste bud"
      zeroText="Ingen afviste bud"
      actionText={result?.count === 0 ? 'Begynd at byde' : 'Vis alle'}
      texts={result?.data?.map((auction) => {
        return {
          title: auction.make + ' ' + auction.model,
          link: `/dashboard/auctions/auction?auctionid=${auction.auctionId}`,
          text: `Bud på ${formattedPriceExpectation(
            auction.currentUserBid?.price?.toString()
          )}`,
          createdAt: auction.currentUserBid?.statusChangeDate!,
        };
      })}
      icon={<img src="/assets/iconly/afviste.svg" width={48} />}
      href={
        result?.count === 0
          ? '/dashboard/auctions/active'
          : '/dashboard/auctions/closedbid'
      }
    />
  );
};
