import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Divider,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import { neutral } from 'src/theme/colors';
import NextLink from 'next/link';
import CountUp from 'react-countup';
import InfoCircleIcon from '@untitled-ui/icons-react/build/esm/InfoCircle';

interface OverviewDefaultCardProps {
  label: string;
  amount: undefined | number;
  maxAmount?: undefined | number;
  decimals?: number;
  caption?: string;
  zeroText?: string;
  infoText?: string;
  icon?: any;
  href?: string;
}

export const OverviewDefaultCard: FC<OverviewDefaultCardProps> = (props) => {
  const {
    amount,
    label,
    href,
    maxAmount,
    decimals,
    caption,
    zeroText,
    infoText,
  } = props;

  return (
    <Card>
      <Stack
        alignItems="center"
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={3}
        sx={{
          px: 4,
          py: 3,
        }}
      >
        <div>
          {props.icon ? (
            props.icon
          ) : (
            <img src="/assets/iconly/iconly-glass-paper.svg" width={48} />
          )}
        </div>
        <Box sx={{ flexGrow: 1 }}>
          <Typography color="text.secondary" variant="body2">
            {label}
          </Typography>
          <Typography
            color="text.primary"
            variant="h4"
            sx={{ textAlign: { xs: 'center', sm: 'unset' } }}
          >
            {amount === undefined && <CircularProgress size="1.5rem" />}
            {amount !== undefined && typeof amount === 'number' && (
              <>
                {zeroText !== undefined && amount === 0 ? (
                  <>
                    <Typography component={'span'} variant="caption">
                      {zeroText}
                    </Typography>
                    <>&nbsp;</>
                  </>
                ) : (
                  <>
                    <CountUp
                      end={amount}
                      separator="."
                      decimal=","
                      decimals={decimals ?? 0}
                    />
                    {amount !== undefined &&
                      typeof amount === 'number' &&
                      maxAmount !== undefined && <>/{maxAmount}</>}
                    {amount !== undefined &&
                      typeof amount === 'number' &&
                      caption && (
                        <Typography
                          marginLeft={1}
                          component={'span'}
                          color="text.secondary"
                          variant="caption"
                        >
                          {caption}
                        </Typography>
                      )}
                  </>
                )}
              </>
            )}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <CardActions>
        <Box width={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <NextLink
              href={href ?? '#'}
              passHref
              color="inherit"
              style={{
                textDecoration: 'none',
                color: neutral[900],
                visibility: href ? 'visible' : 'hidden',
              }}
            >
              <Button
                color="inherit"
                endIcon={
                  <SvgIcon>
                    <ArrowRightIcon />
                  </SvgIcon>
                }
                size="small"
              >
                Vis alle
              </Button>
            </NextLink>
            {infoText && (
              <Box paddingRight={1.5}>
                <Tooltip title={infoText}>
                  <SvgIcon>
                    <InfoCircleIcon />
                  </SvgIcon>
                </Tooltip>
              </Box>
            )}
          </Stack>
        </Box>
      </CardActions>
    </Card>
  );
};

OverviewDefaultCard.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.any,
  icon: PropTypes.any,
  href: PropTypes.string,
};
