import { LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

interface OverviewLoadingChart {
  isLoading: boolean;
}

export const OverviewLoadingChart: FC<OverviewLoadingChart> = (props) => {
  const { isLoading } = props;
  return (
    <Box sx={{ width: '100%', visibility: isLoading ? 'visible' : 'hidden' }}>
      <LinearProgress />
    </Box>
  );
};
