export const stringToNumber = (
  input: string | undefined | null
): number | undefined => {
  if (input === undefined || input === null) {
    return undefined;
  }

  const number = parseFloat(input);
  if (isNaN(number)) {
    return undefined;
  }

  return number;
};
