import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverviewDefaultCard } from './overview-default-card';
import { useApi } from 'src/hooks/use-api';
import { OverviewFilters, OverviewState } from 'src/pages/dashboard/overview';
import { IwFacebookClickCountRequest } from 'src/lib-api';

interface OverviewFacebookClickCountProps extends OverviewFilters {}

export const OverviewFacebookClickCount: FC<OverviewFacebookClickCountProps> = (
  props
) => {
  const { classifiedsStatsApi } = useApi();
  const { startDate, endDate, dealers } = props;
  const [amount, setAmount] = useState<number | undefined>(undefined);

  useEffect(() => {
    const load = async () => {
      setAmount(undefined);

      const request: IwFacebookClickCountRequest = {
        startDate,
        endDate,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await classifiedsStatsApi
        .iwFacebookClickCount(request)
        .then((res) => {
          setAmount(+res);
        })
        .catch((e) => {});
    };

    load();
  }, [classifiedsStatsApi, startDate, endDate, dealers]);

  return (
    <OverviewDefaultCard
      label="Facebook klik"
      amount={amount}
      icon={<img src="/assets/iconly/facebook-clicks.png" width={48} />}
      href={'#overview-iw-facebook-chart'}
    />
  );
};

OverviewFacebookClickCount.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
