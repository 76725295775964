import {
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import LinkExternal01 from "@untitled-ui/icons-react/build/esm/LinkExternal01";
import { FC, useState } from "react";

interface OverviewUpgradeChart {
  shouldUpgrade: boolean;
  title: string;
  text: string;
}

export const OverviewUpgradeChart: FC<OverviewUpgradeChart> = (props) => {
  const { shouldUpgrade, title, text } = props;
  const [hover, setHover] = useState(false);
  return shouldUpgrade ? (
    <Box
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        bgcolor: hover ? "rgb(0,0,0,0.0)" : "rgb(0,0,0,0.1)",
        transition: "background-color, 2000ms, ease, 200ms",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          maxWidth: 400,
          backgroundColor: hover
            ? "rgb(255,255,255,0.8)"
            : "rgb(255,255,255,0.7)",
          transition: "background-color, 2000ms, ease, 200ms",
        }}
      >
        <CardHeader title={title} subheader={text} />
        <CardContent>
          <Typography color="text.secondary" variant="caption">
            Kontakt os på tlf. 93 10 20 50 eller{" "}
            <Typography
              component={"a"}
              target="_blank"
              href={"mailto:salg@bilhandel.dk"}
              color="text.secondary"
              variant="caption"
            >
              salg@bilhandel.dk
            </Typography>{" "}
            for at høre mere om hvordan vi kan hjælpe dig med at få flere
            kunder.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  ) : (
    <></>
  );
};
