import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import type { ApexOptions } from 'apexcharts';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from 'src/components/chart';
import { format } from 'date-fns';
import { useApi } from 'src/hooks/use-api';
import { OverviewFilters } from 'src/pages/dashboard/overview';
import { CombinedLeadsChartRequest } from 'src/lib-api';
import { OverviewLoadingChart } from './overview-loading-chart';
import { useAppStore } from 'src/store/store';

interface OverviewLeadsChartProps extends OverviewFilters {}

type ChartSeries = {
  name: string;
  data: { x: string; y: number }[];
}[];

const useChartOptions = (): ApexOptions => {
  const theme = useTheme();

  return {
    chart: {
      background: 'transparent',
      stacked: true,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
      },
      type: 'bar',
    },
    colors: ['#1067B6', '#2ED381'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            style: {},
          },
        },
      },
    },
    fill: {
      type: 'solid',
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: theme.palette.background.paper,
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 0,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      tickAmount: 1,
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
  };
};

export const OverviewCombinedLeadsChart: FC<OverviewLeadsChartProps> = (
  props
) => {
  const { classifiedsStatsApi } = useApi();
  const { startDate, endDate, dealers } = props;
  const chartOptions = useChartOptions();
  const [chartData, setChartData] = useState<ChartSeries>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { selectedDealers } = useAppStore();

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const request: CombinedLeadsChartRequest = {
        startDate,
        endDate,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await classifiedsStatsApi
        .combinedLeadsChart(request)
        .then((res) => {
          const mapped = res!.map((s) => {
            return {
              name: s.name!,
              data: s.data!.map((d) => {
                return { x: format(d.x!, 'MM-dd-yyyy') + ' GMT', y: d.y! };
              }),
            };
          });

          setChartData(mapped);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    };

    load();
  }, [classifiedsStatsApi, startDate, endDate, dealers]);

  return (
    <Card>
      <CardHeader title="Henvendelser" />
      <CardContent>
        <Chart
          height={360}
          options={chartOptions}
          series={chartData}
          type="bar"
        />
        <OverviewLoadingChart isLoading={isLoading} />
      </CardContent>
    </Card>
  );
};

OverviewCombinedLeadsChart.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
