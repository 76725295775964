import { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import type { ApexOptions } from "apexcharts";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Chart } from "src/components/chart";
import { format } from "date-fns";
import { useApi } from "src/hooks/use-api";
import { OverviewFilters } from "src/pages/dashboard/overview";
import { IwFacebookChartRequest } from "src/lib-api";
import { OverviewLoadingChart } from "./overview-loading-chart";
import { useAuth } from "src/hooks/use-auth";
import { OverviewUpgradeChart } from "./overview-upgrade-chart";

interface OverviewFacebookChartProps extends OverviewFilters {}
type ChartSeries = {
  name: string;
  data: { x: string; y: number }[];
}[];

const chartSeries: ChartSeries = [
  {
    name: "Facebook",
    data: [],
  },
];

const useChartOptions = (): ApexOptions => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      type: "area",
    },
    colors: ["#1067B6", "#2ED381"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: theme.palette.background.paper,
      size: 6,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      type: "datetime",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        title: {
          text: "Kliks",
          style: {
            fontWeight: 500,
          },
        },
        tickAmount: 1,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      {
        title: {
          text: "Visninger",
          style: {
            fontWeight: 500,
          },
        },
        opposite: true,
        tickAmount: 1,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
    ],
  };
};

export const OverviewFacebookChart: FC<OverviewFacebookChartProps> = (
  props
) => {
  const { classifiedsStatsApi } = useApi();
  const { startDate, endDate, dealers } = props;
  const chartOptions = useChartOptions();
  const [chartData, setChartData] = useState<ChartSeries>(chartSeries);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const hasLead = dealers?.filter((p) => p.lead).length !== 0;

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const request: IwFacebookChartRequest = {
        startDate,
        endDate,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await classifiedsStatsApi
        .iwFacebookChart(request)
        .then((res) => {
          const mapped = res!.map((s) => {
            return {
              name: s.name!,
              data: s.data!.map((d) => {
                return { x: format(d.x!, "MM-dd-yyyy") + " GMT", y: d.y! };
              }),
            };
          });
          setChartData(mapped);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    };

    load();
  }, [classifiedsStatsApi, startDate, endDate, dealers]);

  return (
    <Card style={{ position: "relative" }} id="overview-iw-facebook-chart">
      <CardHeader
        title="Facebook"
        subheader="Annoncering betalt af Bilhandel (1 dags forsinkelse)"
      />
      <CardContent>
        <Chart
          height={360}
          options={chartOptions}
          series={chartData}
          type="area"
        />
        <OverviewUpgradeChart
          title="Få flere kunder med et lead-abonnement"
          text="Kunder der har et lead-abonnement hos Bilhandel får
          gratis vist deres biler på Facebook."
          shouldUpgrade={!hasLead}
        />
        <OverviewLoadingChart isLoading={isLoading} />
      </CardContent>
    </Card>
  );
};

OverviewFacebookChart.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
