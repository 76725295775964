import { getInitials } from './get-initials';
import { stringToColor } from './string-to-color';

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: getInitials(name),
  };
};
