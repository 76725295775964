import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import Link from 'next/link';
import { FC, useMemo } from 'react';
import useTimer from 'src/hooks/use-timer';
import { AuctionKeyData } from 'src/lib-api';
import { AuctionDebug } from './auction-debug';

interface AuctionThumbnailCardProps {
  auctionKeyData: AuctionKeyData;
}

export const AuctionThumbnailCard: FC<AuctionThumbnailCardProps> = (props) => {
  const { auctionKeyData: data } = props;

  const { days, hours, minutes, seconds } = useTimer(
    data.expireAt!.toISOString()
  );

  const expired = useMemo(() => {
    return (
      (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) ||
      data.auctionStatus === 'Completed' ||
      data.auctionStatus === 'Cancelled'
    );
  }, [days, hours, minutes, seconds, data.auctionStatus]);

  const texts = (
    <>
      <Typography variant="h6">
        {data.make} {data.model}
      </Typography>
      {data.variant && (
        <Typography
          variant="subtitle1"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre',
          }}
        >
          {data.variant}
        </Typography>
      )}
      <Grid container marginTop={1}>
        <Typography variant="subtitle1">
          {expired ? 'Udløbet' : 'Udløber'} {format(data.expireAt!, 'dd/MM')}{' '}
          kl. {format(data.expireAt!, 'HH:mm')}
        </Typography>
      </Grid>
    </>
  );

  return (
    <>
      <Link
        href={`/dashboard/auctions/auction?auctionid=${data.auctionId!}`}
        style={{ textDecoration: 'none' }}
      >
        <Card sx={{ marginRight: { xs: '16px' }, marginTop: '20px' }}>
          <CardContent>
            <div style={{ width: '100%' }}>
              <Box
                display={'flex'}
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'flex-start',
                  flexDirection: { xs: 'column' },
                }}
              >
                <Box
                  alignSelf={'stretch'}
                  sx={{
                    alignItems: 'center',
                    backgroundColor: 'neutral.50',
                    backgroundImage: `url(${data.attachment?.url!})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    borderRadius: 1,
                    justifyContent: 'center',
                    overflow: 'hidden',
                    width: { xs: '205px' },
                    minHeight: '150px',
                    marginBottom: 2,
                  }}
                ></Box>
                <Box width={{ xs: '205px' }} flexGrow={1} alignSelf={'stretch'}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box>{texts}</Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </div>

            <AuctionDebug auction={data} />
          </CardContent>
        </Card>
      </Link>
    </>
  );
};
