import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverviewDefaultCard } from './overview-default-card';
import { useApi } from 'src/hooks/use-api';
import { paths } from 'src/paths';
import { Dealer, LeadCountRequest } from 'src/lib-api';
import { OverviewFilters } from 'src/pages/dashboard/overview';

interface OverviewLeadCountProps extends OverviewFilters {}

export const OverviewLeadCount: FC<OverviewLeadCountProps> = (props) => {
  const { classifiedsStatsApi } = useApi();
  const { startDate, endDate, dealers } = props;
  const [amount, setAmount] = useState<number | undefined>(undefined);

  useEffect(() => {
    const load = async () => {
      setAmount(undefined);

      const request: LeadCountRequest = {
        startDate,
        endDate,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await classifiedsStatsApi
        .leadCount(request)
        .then((res) => {
          setAmount(+res);
        })
        .catch((e) => {});
    };

    load();
  }, [classifiedsStatsApi, startDate, endDate, dealers]);

  return (
    <OverviewDefaultCard
      label="Henvendelser"
      amount={amount}
      href={paths.dashboard.leads.leads}
      icon={<img src="/assets/iconly/lead.png" width={48} />}
    />
  );
};

OverviewLeadCount.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
