import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { Button, Fab, Theme } from '@mui/material';
import {
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Scrollbar } from 'src/components/scrollbar';
import { Dealer } from 'src/lib-api';
import RefreshCcw01Icon from '@untitled-ui/icons-react/build/esm/RefreshCcw01';
import { isAfter, isBefore } from 'date-fns';
import { useAppStore } from 'src/store/store';
import { useAuth } from 'src/hooks/use-auth';

export interface Filters {
  startDate?: Date;
  endDate?: Date;
  dealers?: Dealer[];
}

interface OverviewSidebarProps {
  container?: HTMLDivElement | null;
  filters?: Filters;
  onFiltersChange?: (filters: Filters) => void;
  onClose?: () => void;
  open?: boolean;
}

export const OverviewSidebar: FC<OverviewSidebarProps> = (props) => {
  const {
    container,
    filters: initialfilters = {},
    onClose,
    onFiltersChange,
    open,
    ...other
  } = props;
  const { user, dealers } = useAuth();
  const { selectDealer, deselectDealer } = useAppStore();
  const [filters, setFilters] = useState<Filters>(initialfilters);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const handleStartDateChange = useCallback(
    (date: Date | null): void => {
      const newFilters: Filters = {
        ...filters,
        startDate: date || undefined,
      };

      // Prevent end date to be before start date
      if (newFilters.endDate && date && isAfter(date, newFilters.endDate)) {
        newFilters.endDate = date;
      }

      setFilters((prevState) => newFilters);
    },
    [filters]
  );

  const handleEndDateChange = useCallback(
    (date: Date | null): void => {
      const newFilters: Filters = {
        ...filters,
        endDate: date || undefined,
      };

      // Prevent start date to be after end date
      if (
        newFilters.startDate &&
        date &&
        isBefore(date, newFilters.startDate)
      ) {
        newFilters.startDate = date;
      }

      setFilters((prevState) => newFilters);
    },
    [filters]
  );

  const handleCustomerToggleAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      let selectedDealers: Dealer[];

      selectedDealers = [];

      if (event.target.checked) {
        selectedDealers = dealers;
        dealers.forEach((dealer) => selectDealer(dealer));
      } else {
        selectedDealers = [];
        dealers.forEach((dealer) => deselectDealer(dealer.id!));
      }

      setFilters((prevState) => {
        return {
          ...prevState,
          dealers: selectedDealers,
        };
      });
    },
    [dealers, selectDealer, deselectDealer]
  );

  const handleCustomerToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      let selectedDealers: Dealer[];

      selectedDealers = [];

      if (event.target.checked) {
        var selectedDealer = dealers.find(
          (c) => c.id?.toString() === event.target.value
        );

        selectDealer(selectedDealer!);
        selectedDealers = [...(filters.dealers || []), selectedDealer!];
      } else {
        selectedDealers = (filters.dealers || []).filter(
          (dealer) => dealer.id?.toString() !== event.target.value
        );

        deselectDealer(+event.target.value);
      }

      setFilters((prevState) => {
        return {
          ...prevState,
          dealers: selectedDealers,
        };
      });
    },
    [dealers, selectDealer, filters.dealers, deselectDealer]
  );

  const handleApplyFilter = useCallback(() => {
    onFiltersChange?.({
      ...filters,
    });
  }, [filters, onFiltersChange]);

  const content = (
    <div>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        sx={{ p: 3 }}
      >
        <Typography variant="h5">Filtre</Typography>
        {!lgUp && (
          <IconButton onClick={onClose}>
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        )}
      </Stack>
      <Stack spacing={3} sx={{ p: 3 }}>
        <div>
          <FormLabel
            sx={{
              display: 'block',
              mb: 2,
            }}
          >
            Dato
          </FormLabel>
          <Stack spacing={2}>
            <DatePicker
              format="dd/MM/yyyy"
              label="Fra (inkl.)"
              onChange={handleStartDateChange}
              // renderInput={(inputProps) => (
              //   <TextField
              //     {...inputProps}
              //     onKeyDown={(e) => e.preventDefault()}
              //   />
              // )}
              value={filters.startDate || null}
              disableFuture
            />
            <DatePicker
              format="dd/MM/yyyy"
              label="Til (inkl.)"
              onChange={handleEndDateChange}
              // renderInput={(inputProps) => (
              //   <TextField
              //     {...inputProps}
              //     onKeyDown={(e) => e.preventDefault()}
              //   />
              // )}
              value={filters.endDate || null}
              disableFuture
            />
          </Stack>
        </div>
        <div>
          <FormLabel
            sx={{
              display: 'block',
              mb: 2,
            }}
          >
            Afdelinger
          </FormLabel>
          <FormGroup
            sx={{
              py: 1,
              px: 1.5,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.dealers?.length === dealers.length}
                  onChange={handleCustomerToggleAll}
                />
              }
              label={
                <Box>
                  <Typography>Afdelinger</Typography>
                </Box>
              }
            />
          </FormGroup>
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.50',
              borderColor: 'divider',
              borderRadius: 1,
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <FormGroup
              sx={{
                py: 1,
                px: 1.5,
              }}
            >
              {dealers.map((dealer) => {
                const isChecked = filters.dealers
                  ?.map((c) => c.id)
                  .includes(dealer.id);
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCustomerToggle}
                      />
                    }
                    key={dealer.id}
                    label={
                      <Box>
                        <Typography>
                          {dealer.city}, {dealer.streetName}
                        </Typography>
                      </Box>
                    }
                    value={dealer.id}
                  />
                );
              })}
            </FormGroup>
          </Box>
        </div>
        <div style={{ position: 'sticky', bottom: '24px' }}>
          <Button
            onClick={handleApplyFilter}
            fullWidth
            startIcon={
              <SvgIcon>
                <RefreshCcw01Icon />
              </SvgIcon>
            }
            variant="contained"
          >
            Anvend
          </Button>
        </div>
      </Stack>
    </div>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={open}
        PaperProps={{
          elevation: 16,
          sx: {
            border: 'none',
            borderRadius: 2.5,
            position: 'relative',
            width: 380,
          },
        }}
        SlideProps={{ container }}
        variant="persistent"
        sx={{ p: 3 }}
        {...other}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      hideBackdrop
      ModalProps={{
        container,
        sx: {
          pointerEvents: 'none',
          position: 'absolute',
        },
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: 380,
          pointerEvents: 'auto',
          position: 'absolute',
        },
      }}
      SlideProps={{ container }}
      variant="temporary"
      {...other}
    >
      {content}
    </Drawer>
  );
};

OverviewSidebar.propTypes = {
  container: PropTypes.any,
  // @ts-ignore
  filters: PropTypes.object,
  onClose: PropTypes.func,
  onFiltersChange: PropTypes.func,
  open: PropTypes.bool,
};
