import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Chip,
  Container,
  Tooltip,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {
  Box,
  Button,
  Divider,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { Seo } from 'src/components/seo';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import { OverviewContainer } from 'src/sections/dashboard/overview/overview-container';
import { OverviewSidebar } from 'src/sections/dashboard/overview/overview-sidebar';
import type { Page as PageType } from 'src/types/page';
import { OverviewTips } from 'src/sections/dashboard/overview/overview-tips';
import { OverviewBanner } from 'src/sections/dashboard/overview/overview-banner';
import { OverviewInbox } from 'src/sections/dashboard/overview/overview-inbox';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { useSettings } from 'src/hooks/use-settings';
import { OverviewPriceChangesCount } from 'src/sections/dashboard/overview/overview-price-changes-count';
import { OverviewActiveCount } from 'src/sections/dashboard/overview/overview-active-count';
import { OverviewSoldCount } from 'src/sections/dashboard/overview/overview-sold-count';
import { OverviewNewCount } from 'src/sections/dashboard/overview/overview-new-count';
import { OverviewLeadCount } from 'src/sections/dashboard/overview/overview-lead-count';
import { OverviewViewsChart } from 'src/sections/dashboard/overview/overview-views-chart';
import { OverviewCallTrackingChart } from 'src/sections/dashboard/overview/overview-call-tracking-chart';
import { OverviewCombinedLeadsChart } from 'src/sections/dashboard/overview/overview-combined-leads-chart';
import { AnsweringStats } from 'src/sections/dashboard/calls/answering-stats';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { OverviewViewCount } from 'src/sections/dashboard/overview/overview-view-count';
import { OverviewFacebookChart } from 'src/sections/dashboard/overview/overview-iw-facebook-chart';
import { OverviewFacebookImpressionCount } from 'src/sections/dashboard/overview/overview-iw-facebook-impression-count';
import { OverviewFacebookClickCount } from 'src/sections/dashboard/overview/overview-iw-facebook-click-count';
import { Dealer } from 'src/lib-api';
import { useAppStore } from 'src/store/store';
import { OverviewFeedback } from 'src/sections/dashboard/overview/overview-feedback';
import { OverviewClickFacebookChart } from 'src/sections/dashboard/overview/overview-click-facebook-chart';
import { OverviewRatingAvarage } from 'src/sections/dashboard/overview/overview-rating-average';
import { useAuth } from 'src/hooks/use-auth';
import { AuctionsNew } from 'src/sections/dashboard/overview/auctions/auctions-new';
import { AuctionsFavorite } from 'src/sections/dashboard/overview/auctions/auctions-favorite';
import { LatestWon } from 'src/sections/dashboard/overview/auctions/auctions-latest-won';
import { AuctionsOpenBids } from 'src/sections/dashboard/overview/auctions/auctions-openbids';
import { AuctionsClosedBids } from 'src/sections/dashboard/overview/auctions/auctions-closedbids';
import { AuctionsMessages } from 'src/sections/dashboard/overview/auctions/auctions-messages';
import Cookies from 'js-cookie';
import { GridList1 } from 'src/sections/components/grid-lists/grid-list-1';
import { GridList2 } from 'src/sections/components/grid-lists/grid-list-2';
import { GridList3 } from 'src/sections/components/grid-lists/grid-list-3';
import { OverviewActiveFavoriteCount } from 'src/sections/dashboard/overview/overview-active-favorite-count';

export interface OverviewFilters {
  endDate?: Date;
  startDate?: Date;
  dealers?: Dealer[];
}

export interface OverviewState {
  filters: OverviewFilters;
}

const useAsyncDataFilter = (selectedDealers: Dealer[]) => {
  const [state, setState] = useState<OverviewState>({
    filters: {
      startDate: subDays(startOfDay(new Date()), 30),
      endDate: startOfDay(new Date()),
      dealers: selectedDealers,
    },
  });

  const handleFiltersChange = useCallback((filters: OverviewFilters): void => {
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);

  return { state, handleFiltersChange };
};

const Page: PageType = () => {
  const { selectedDealers } = useAppStore();
  const dataFilter = useAsyncDataFilter(selectedDealers);

  const settings = useSettings();
  const { user } = useAuth();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const handleFiltersToggle = useCallback((): void => {
    setOpenSidebar((prevState) => !prevState);
  }, []);

  const handleFiltersClose = useCallback((): void => {
    setOpenSidebar(false);
  }, []);

  const handleFiltersChange = useCallback(
    (filters: OverviewFilters): void => {
      dataFilter.handleFiltersChange(filters);
      setOpenSidebar(false);
    },
    [dataFilter]
  );

  if (!dataFilter || !dataFilter.state) {
    return null;
  }

  const startDate = startOfDay(dataFilter.state.filters.startDate!);
  const endDate = startOfDay(dataFilter.state.filters.endDate!);

  const filterLabel = `Afdelinger (${
    dataFilter.state.filters.dealers?.length
  }), ${format(startDate, 'MMM dd')} - ${format(endDate, 'MMM dd, yyyy')}`;
  const lead = dataFilter.state.filters.dealers?.some((dealer) => dealer.lead);

  return (
    <>
      <Seo title="Dashboard: Dine tal" />
      <Divider />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          ref={rootRef}
          sx={{
            bottom: 0,
            display: 'flex',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <OverviewSidebar
            container={rootRef.current}
            filters={dataFilter.state.filters}
            onFiltersChange={handleFiltersChange}
            onClose={handleFiltersClose}
            open={openSidebar}
          />
          <OverviewContainer open={openSidebar}>
            <Stack spacing={4}>
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
                spacing={3}
              >
                <div>
                  {lead ? (
                    <Typography variant="h4">Dine tal på Bilhandel</Typography>
                  ) : (
                    <Typography variant="h4">SmartSalg</Typography>
                  )}
                </div>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    endIcon={
                      <SvgIcon>
                        <ArrowDropDownIcon />
                      </SvgIcon>
                    }
                    onClick={handleFiltersToggle}
                  >
                    {filterLabel}
                  </Button>
                </Stack>
              </Stack>
              <Container maxWidth={settings.stretch ? false : 'xl'}>
                <Grid
                  container
                  disableEqualOverflow
                  spacing={{
                    xs: 3,
                    lg: 4,
                  }}
                >
                  {lead && (
                    <>
                      <Grid xs={12} md={4}>
                        <OverviewViewCount {...dataFilter.state.filters} />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <OverviewLeadCount {...dataFilter.state.filters} />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <OverviewActiveCount {...dataFilter.state.filters} />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <OverviewSoldCount {...dataFilter.state.filters} />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <OverviewNewCount {...dataFilter.state.filters} />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <OverviewPriceChangesCount
                          {...dataFilter.state.filters}
                        />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <OverviewActiveFavoriteCount
                          {...dataFilter.state.filters}
                        />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <OverviewFacebookImpressionCount
                          {...dataFilter.state.filters}
                        />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <OverviewFacebookClickCount
                          {...dataFilter.state.filters}
                        />
                      </Grid>
                      <Grid xs={12} md={4}>
                        <OverviewRatingAvarage {...dataFilter.state.filters} />
                      </Grid>
                      <Grid xs={12} md={4}></Grid>
                      <Grid xs={12} md={4}></Grid>
                      <Grid xs={12} md={7}>
                        <OverviewCombinedLeadsChart
                          {...dataFilter.state.filters}
                        />
                      </Grid>
                      <Grid xs={12} md={5}>
                        <OverviewInbox {...dataFilter.state.filters} />
                      </Grid>
                      <Grid xs={12} md={7}>
                        <OverviewBanner />
                      </Grid>
                      <Grid xs={12} md={5}>
                        <OverviewTips
                          sx={{ height: '100%' }}
                          tips={[
                            {
                              title: 'Vælg tidsperiode og afdelinger',
                              content:
                                'Du kan indstille hvilken periode du vil kigge på, samt vælge afdelinger til og fra i øverste højre hjørne.',
                            },
                            {
                              title: 'Følg op på dine leads',
                              content:
                                'De mest succesfulde forhandlere kontakter potentielle købere op til 6 gange, for at sikre handlen.',
                            },
                            {
                              title: 'Se dine henvendelser',
                              content:
                                'Du kan se alle mails og opkald du har modtaget under Henvendelser i menuen til venstre',
                            },
                          ]}
                        />
                      </Grid>
                      <Grid xs={12} md={7}>
                        <OverviewViewsChart {...dataFilter.state.filters} />
                      </Grid>

                      <Grid xs={12} md={5}>
                        <OverviewFeedback {...dataFilter.state.filters} />
                      </Grid>

                      <Grid xs={12} md={7}>
                        <OverviewCallTrackingChart
                          {...dataFilter.state.filters}
                        />
                      </Grid>

                      <Grid xs={12} md={5}>
                        <AnsweringStats {...dataFilter.state.filters} />
                      </Grid>
                    </>
                  )}

                  {lead && (
                    <Grid xs={12}>
                      <Typography variant="h4">SmartSalg</Typography>
                    </Grid>
                  )}
                  <Grid xs={12} md={12}>
                    <AuctionsNew />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <AuctionsMessages />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <AuctionsOpenBids />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <AuctionsClosedBids />
                  </Grid>
                  <Grid xs={12} md={8}>
                    <AuctionsFavorite />
                  </Grid>
                  <Grid xs={12} md={4}>
                    <LatestWon />
                  </Grid>
                  <Grid xs={12}>
                    <Typography variant="h4">Facebook annoncering</Typography>
                  </Grid>
                  <Grid xs={12}>
                    <OverviewFacebookChart {...dataFilter.state.filters} />
                  </Grid>
                  <Grid xs={12}>
                    <OverviewClickFacebookChart {...dataFilter.state.filters} />
                  </Grid>
                </Grid>
              </Container>
            </Stack>
          </OverviewContainer>
        </Box>
      </Box>
    </>
  );
};

Page.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default Page;
