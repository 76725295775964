import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverviewDefaultCard } from '../overview-default-card';
import { useApi } from 'src/hooks/use-api';
import {
  AuctionKeyDataSearchResponse,
  AuctionSearchRequest,
  Dealer,
  SearchAuctionsRequest,
  SortDir,
  VipViewCountRequest,
} from 'src/lib-api';
import { OverviewFilters } from 'src/pages/dashboard/overview';
import { AuctionsDefaultCard } from './auctions-default-card';

interface AuctionsFavoriteProps {}

export const AuctionsFavorite: FC<AuctionsFavoriteProps> = (props) => {
  const { auctionApi } = useApi();
  const [result, setResult] = useState<
    AuctionKeyDataSearchResponse | undefined
  >(undefined);

  useEffect(() => {
    const load = async () => {
      setResult(undefined);

      const request: SearchAuctionsRequest = {
        rowPerPage: 10,
        page: 0,
        filters: {
          status: 'favorite',
        },
        sortBy: 'expireAt',
        sortDir: SortDir.Desc,
      };

      await auctionApi
        .auctionSearch({ searchAuctionsRequest: request })
        .then((res) => {
          setResult(res);
        })
        .catch((e) => {});
    };

    load();
  }, [auctionApi]);

  return (
    <AuctionsDefaultCard
      label="Favoritter"
      zeroText="Ingen favoritter"
      auctionResult={result}
      actionText={result?.count === 0 ? 'Find favoritter' : 'Vis alle'}
      icon={<img src="/assets/iconly/favoritter2.svg" width={48} />}
      href={
        result?.count === 0
          ? '/dashboard/auctions/active'
          : '/dashboard/auctions/favorite'
      }
    />
  );
};
