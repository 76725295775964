import { FC, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatDistanceStrict } from "date-fns";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import InfoCircleIcon from "@untitled-ui/icons-react/build/esm/InfoCircle";
import RefreshCcw01Icon from "@untitled-ui/icons-react/build/esm/RefreshCcw01";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  createTheme,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { useApi } from "src/hooks/use-api";
import {
  CombinedLead,
  CombinedLeadSearchRequest,
  Email,
  EmailSearchRequest,
  SortDir,
} from "src/lib-api";
import { paths } from "src/paths";
import NextLink from "next/link";
import { neutral } from "src/theme/colors";
import { useRouter } from "next/router";
import { stringAvatar } from "src/utils/string-avatar";
import { OverviewFilters } from "src/pages/dashboard/overview";
import { OverviewLoadingChart } from "./overview-loading-chart";

interface OverviewFeedbackProps extends OverviewFilters {}

export const OverviewFeedback: FC<OverviewFeedbackProps> = (props) => {
  const { leadApi } = useApi();
  const { dealers } = props;
  const [emailLeads, setEmails] = useState<CombinedLead[] | undefined | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const router = useRouter();

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const combinedLeadSearchRequest: CombinedLeadSearchRequest = {
        rowPerPage: 5,
        page: 0,
        filters: { query: "", onlyWithSurvey: true },
        sortBy: "surveyDate",
        sortDir: SortDir.Desc,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await leadApi
        .leadSearch({ combinedLeadSearchRequest })
        .then((res) => {
          setEmails(res?.data);
        })
        .catch((e) => {})
        .finally(() => setIsLoading(false));
    };

    load();
  }, [leadApi, dealers]);

  return (
    <Card>
      <CardHeader
        title="Tilbagemeldinger fra kunder"
        action={
          <Tooltip title="Bilhandel sender et spørgeskema til brugerne 14 dage efter de har kontaktet en forhandler, for at høre mere om deres oplevelse. Vi ringer også en del af dem op. Du kan se kundernes tilbagemeldinger her.">
            <SvgIcon>
              <InfoCircleIcon />
            </SvgIcon>
          </Tooltip>
        }
      />

      {!emailLeads && (
        <Box width={1} justifyContent={"center"} display={"flex"} padding={2}>
          <CircularProgress />
        </Box>
      )}

      {emailLeads?.length === 0 && (
        <Typography align="center" padding={2}>
          Ingen tilbagemeldinger
        </Typography>
      )}

      <List disablePadding>
        {emailLeads?.map((lead) => {
          const ago = formatDistanceStrict(
            lead.surveyResponse?.createdAt!,
            new Date(),
            {
              addSuffix: true,
            }
          );

          return (
            <ListItem
              onClick={() =>
                router.push(paths.dashboard.leads.leads + "?onlySurvey=true")
              }
              key={lead.id!}
              sx={{
                "&:hover": {
                  backgroundColor: "action.hover",
                  cursor: "pointer",
                },
              }}
            >
              <ListItemAvatar>
                <Avatar
                  {...stringAvatar(
                    lead.surveyResponse?.name
                      ? lead.surveyResponse.name
                      : lead.type == "Email"
                      ? lead.name!.toUpperCase()
                      : lead.phone!
                  )}
                />
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    variant="subtitle2"
                  >
                    {lead.surveyResponse?.name
                      ? lead.surveyResponse.name
                      : lead.type == "Email"
                      ? lead.name
                      : lead.phone}
                  </Typography>
                }
                secondary={
                  <Typography
                    color="text.secondary"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    variant="body2"
                  >
                    {lead.message}
                  </Typography>
                }
                sx={{ pr: 2 }}
              />
              <Typography
                color="text.secondary"
                sx={{ whiteSpace: "nowrap" }}
                variant="caption"
              >
                {ago}
              </Typography>
            </ListItem>
          );
        })}
      </List>
      <Box padding={2}>
        <OverviewLoadingChart isLoading={isLoading} />
      </Box>
      <Divider />
      <CardActions>
        <NextLink
          href={paths.dashboard.leads.leads + "?onlySurvey=true"}
          passHref
          color="inherit"
          style={{ textDecoration: "none", color: neutral[900] }}
        >
          <Button
            color="inherit"
            endIcon={
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            }
            size="small"
          >
            Vis alle
          </Button>
        </NextLink>
      </CardActions>
    </Card>
  );
};

OverviewFeedback.propTypes = {};
