import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import type { ApexOptions } from 'apexcharts';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from 'src/components/chart';
import { format } from 'date-fns';
import { useApi } from 'src/hooks/use-api';
import { OverviewFilters } from 'src/pages/dashboard/overview';
import { VipViewCountRequest } from 'src/lib-api';
import { OverviewLoadingChart } from './overview-loading-chart';

interface OverviewViewsChartProps extends OverviewFilters {}

type ChartSeries = {
  name: string;
  data: { x: string; y: number }[];
}[];

const chartSeries: ChartSeries = [
  {
    name: 'Bilvisninger',
    data: [],
  },
];

const useChartOptions = (): ApexOptions => {
  const theme = useTheme();

  return {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      type: 'area',
    },
    colors: ['#1067B6', '#2ED381'],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0,
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: theme.palette.background.paper,
      size: 6,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      tickAmount: 1,
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
  };
};

export const OverviewViewsChart: FC<OverviewViewsChartProps> = (props) => {
  const { classifiedsStatsApi } = useApi();
  const { startDate, endDate, dealers } = props;
  const chartOptions = useChartOptions();
  const [chartData, setChartData] = useState<ChartSeries>(chartSeries);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const request: VipViewCountRequest = {
        startDate,
        endDate,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await classifiedsStatsApi
        .vipViewsChart(request)
        .then((res) => {
          const mapped = res!.map((s) => {
            return {
              name: s.name!,
              data: s.data!.map((d) => {
                return { x: format(d.x!, 'MM-dd-yyyy') + ' GMT', y: d.y! };
              }),
            };
          });
          setChartData(mapped);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    };

    load();
  }, [classifiedsStatsApi, startDate, endDate, dealers]);

  return (
    <Card id="overview-views-chart">
      <CardHeader title="Bilhandel.dk bilvisninger" />
      <CardContent>
        <Chart
          height={360}
          options={chartOptions}
          series={chartData}
          type="area"
        />
        <OverviewLoadingChart isLoading={isLoading} />
      </CardContent>
    </Card>
  );
};

OverviewViewsChart.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
