import { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverviewDefaultCard } from './overview-default-card';
import { useApi } from 'src/hooks/use-api';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import { paths } from 'src/paths';
import { Dealer, PriceChangeCountRequest } from 'src/lib-api';
import { OverviewFilters } from 'src/pages/dashboard/overview';

interface OverviewPriceChangesCountProps extends OverviewFilters {}

export const OverviewPriceChangesCount: FC<OverviewPriceChangesCountProps> = (
  props
) => {
  const { classifiedsStatsApi } = useApi();
  const { startDate, endDate, dealers } = props;
  const [amount, setAmount] = useState<number | undefined>(undefined);

  useEffect(() => {
    const load = async () => {
      setAmount(undefined);

      const request: PriceChangeCountRequest = {
        startDate,
        endDate,
        dealerIds:
          dealers && dealers?.length > 0
            ? dealers.map((x) => x.id!)
            : undefined,
      };

      await classifiedsStatsApi
        .priceChangeCount(request)
        .then((res) => {
          setAmount(+res);
        })
        .catch(() => {});
    };

    load();
  }, [classifiedsStatsApi, startDate, endDate, dealers]);

  return (
    <OverviewDefaultCard
      label="Prisændringer"
      amount={amount}
      href={paths.dashboard.classifieds.priceChange}
      icon={<img src="/assets/iconly/price-changes.png" width={48} />}
    />
  );
};

OverviewPriceChangesCount.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
