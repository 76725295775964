import numeral from 'numeral';
import { stringToNumber } from './string-to-number';

export const formattedPriceExpectation = (
  priceExpectation: string | undefined | null
): string => {
  const empty = '-';
  if (
    priceExpectation === undefined ||
    priceExpectation === null ||
    priceExpectation.trim().length == 0
  ) {
    return empty;
  }

  const replacedPriceExpectation = priceExpectation
    .replaceAll(',', '')
    .replaceAll('.', '')
    .replaceAll('kr', '');

  if (/^\d+$/.test(replacedPriceExpectation) == false) {
    return priceExpectation;
  }

  const asNumber = stringToNumber(replacedPriceExpectation);
  if (asNumber === undefined) {
    return empty;
  }

  return `${numeral(asNumber).format('0,0')} kr`;
};
