import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { AuctionDetailData, AuctionKeyData } from 'src/lib-api';
import { useApi } from 'src/hooks/use-api';
import { format } from 'date-fns';
import Cookies from 'js-cookie';

interface AuctionDebugProps {
  auction: AuctionKeyData | AuctionDetailData;
}

export const AuctionDebug: FC<AuctionDebugProps> = (props) => {
  const { auction } = props;
  const DISPLAY_DEBUGER = Cookies.get('testtesttest') === 'true';

  if (!DISPLAY_DEBUGER) {
    return <></>;
  }

  return (
    <Box>
      <pre style={{ whiteSpace: 'pre-wrap' }}>
        id: {auction.auctionId} <br />
        status: {auction.auctionStatus} <br />
        created at:{' '}
        {auction.createdAt
          ? format(auction.createdAt!, 'dd-MM-yyyy HH:mm')
          : '-'}
        <br />
        expire at:{' '}
        {auction.expireAt ? format(auction.expireAt!, 'dd-MM-yyyy HH:mm') : '-'}
        <br />
        hasWinner: {auction.hasWinner === true ? 'true' : 'false'}
        <br />
        currentUserBid: {auction.currentUserBid ? 'true' : 'false'}
        <br />
        {auction.currentUserBid && (
          <>
            - isHighestBid:{' '}
            {auction.currentUserBid.isHighestBid ? 'true' : 'false'}
            <br />- wasHighestBid:{' '}
            {auction.currentUserBid.wasHighestBid ? 'true' : 'false'}
            <br />- price: {auction.currentUserBid.price}
            <br />- status: {auction.currentUserBid.status}
            <br />- bidCountTotal: {auction.currentUserBid.bidCountTotal}
            <br />- createdDate:{' '}
            {auction.currentUserBid.createdDate
              ? format(
                  auction.currentUserBid.createdDate!,
                  'dd-MM-yyyy HH:mm:ss'
                )
              : '-'}
            <br />- statusChangeDate:{' '}
            {auction.currentUserBid.statusChangeDate
              ? format(
                  auction.currentUserBid.statusChangeDate!,
                  'dd-MM-yyyy HH:mm:ss'
                )
              : '-'}
            <br />
          </>
        )}
      </pre>
    </Box>
  );
};
