import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, SvgIcon, Typography } from '@mui/material';
import { useSettings } from 'src/hooks/use-settings';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const OverviewBanner: FC = (props) => {
  const { handleDrawerOpen } = useSettings();

  return (
    <Stack
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      spacing={4}
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark'
            ? 'primary.darkest'
            : 'primary.lightest',
        borderRadius: 2.5,
        p: 4,
      }}
      {...props}
    >
      <Box
        sx={{
          width: 300,
          '& img': {
            width: '100%',
          },
        }}
      >
        <img src="/assets/dude.png" />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography color="primary.main" variant="overline">
          Din succes er en forudsætning for vores succes
        </Typography>
        <Typography color="text.primary" sx={{ mt: 2 }} variant="h6">
          Du er meget velkommen til at kontakte os!
        </Typography>
        <Typography color="text.primary" sx={{ mt: 1 }} variant="body1">
          Hvis du har spørgsmål til dine tal, eller hvis du gerne vil vide
          hvordan din forretning klarer sig ift. gennemsnittet, så skriv endelig
          til os.
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button
            color="primary"
            href="mailto:salg@bilhandel.dk"
            startIcon={
              <SvgIcon>
                <MailOutlineIcon />
              </SvgIcon>
            }
            variant="contained"
          >
            Kontakt os
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

OverviewBanner.propTypes = {
  onDismiss: PropTypes.func,
};
